require('./bootstrap');


//
//
// window.Vue = require('vue');
// import VueRouter from 'vue-router'
// import App from './components/App.vue';
//
// Vue.use(VueRouter)
//
// Vue.config.devtools = true;
// Vue.config.performance = true;
//
// import Events from './components/Events/Events';
// import Event from './components/Event/Event';
// import EventInstance from './components/EventInstance/EventInstance';
// import EventApplication from './components/EventApplication/EventApplication';
//
// // 2. Define some routes
// // Each route should map to a component. The "component" can
// // either be an actual component constructor created via
// // `Vue.extend()`, or just a component options object.
// // We'll talk about nested routes later.
// const routes = [
//     { path: '/event/:slug', component:  Event},
//     { path: '/event/:slug/:instanceSlug', component:  EventInstance},
//     { path: '/event/:slug/:instanceSlug/application', component:  EventApplication},
//     { path: '*', component:  Events},
// ]
//
//
// // var mixin = {
// //     data: function () {
// //         return {
// //             userContext:  window.userContext,
// //             eventProps:  window.eventProps,
// //             eventInfo:  window.eventInfo,
// //         }
// //     }
// // }
// // Vue.mixin({
// //     mixin
// // })
//
// import Vuetify from 'vuetify';
// import hu from 'vuetify/es5/locale/hu'
// Vue.use(Vuetify);
// const     vuetify = new Vuetify({
//     lang: {
//         locales: { hu },
//         current: 'hu',
//     },});
//
// const router = new VueRouter({routes})
//
// const app = new Vue({
//     router,
//     vuetify,
//     components: {App,Events,        Event,        EventInstance,        EventApplication},
//     render: h => h(App)
// }).$mount('#app');
